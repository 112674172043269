.BgWithText {
  height: 15.7vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.999;
  background-repeat: round !important;
  background-size: contain !important;
}

.BgWithText:after {
  position: absolute;
  content: ' ';
  top: 8vh;
  left: 0;
  height: 15.7vw;
  width: 100%;
  z-index: -1;
  background: black 0% 0% no-repeat padding-box;
  opacity: 0.4;
}

.Title {
  font-size: 2.34vw;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-weight: bold;
  z-index: 1;
}

.OverlayText {
  position: absolute;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 0.24;
  font-weight: bold;
  font-size: 4vw;
}

@media only screen and (max-width: 600px) {
  .BgWithText {
    height: 109.3vw;
    margin-top: -1px;
  }
  .BgWithText:after {
    top: 13.5vw;
    height: 109.3vw;
    margin-top: -1px;
  }
  .Title {
    text-align: center;
    font-size: 6.5vw;
  }
  .OverlayText {
    font-size: 8.5vw;
    text-align: center;
  }
}
