.Container {
  margin: 8vw;
}

.Container p {
  font-weight: 500;
  font-size: 1.3vw;
}

.GraduateImage {
  height: 28.08vw;
  width: 28.08vw;
}

.Title {
  color: #800e12;
  opacity: 1;
  font-weight: 500;
  font-size: 1.2vw;
}

.Card {
  background: white;
  border: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 28.38vw;
  width: 22.6vw;
  box-shadow: 30px 30px 50px #00000012;
  border: 3px solid transparent;
  border-radius: 1vw;
  position: relative;
}

.Card:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 28.38vw;
  width: 22.6vw;
  background: #800e12 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border: 3px solid #800e12;
  border-radius: 1vw;
  position: relative;
}

.CardOverlay {
  position: absolute;
  height: 28.38vw;
  width: 22.6vw;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 30px 30px 50px #00000012;
  border: 0.15vw solid #800e12;
  border-radius: 1vw;
  top: 0;
  z-index: -1;
  margin: 13px;
}

.CardTitle {
  text-align: center;
  font-size: 2.1vw;
  font-weight: bold;
}

.CardContent {
  line-height: 1.7vw;
  margin: 1.7vw;
  text-align: center;
  font-size: 1vw;
}

.CardsContainer {
  margin-top: 6vw;
}

.Cards {
  margin-top: 3vw;
  display: grid;
  grid-row-gap: 6.2vw;
  grid-template-columns: auto auto auto;
}

.WhiteCard {
  background: white;
  border: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 28.38vw;
  width: 22.6vw;
  box-shadow: 30px 30px 50px #00000012;
  border: 3px solid transparent;
  border-radius: 1vw;
  position: relative;
}

.TextsContainer {
  margin-top: 5.5vw;
}

.TextsContainerBold {
  font-weight: 700;
  margin-top: 5.5vw;
}

.ContainerHead {
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .Container {
    margin: 8vw 7.2vw;
    color: #312e2e;
  }

  .Container p {
    font-size: 3.2vw;
    line-height: 2;
  }
  .GraduateImage {
    height: 92.1vw;
    width: 98.1vw;
  }
  .ImageContainer {
    margin-left: -8vw;
  }
  .Title {
    font-size: 4vw;
  }
  .ContainerHead {
    display: block;
  }

  .Card {
    border-radius: 4vw;
    height: 104.8vw;
    width: 78.4vw;
  }

  .Card:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    background: #800e12 0% 0% no-repeat padding-box;
    box-shadow: 30px 30px 50px #00000012;
    border: 3px solid #800e12;
    position: relative;
    border-radius: 4vw;
    height: 104.8vw;
    width: 78.4vw;
  }

  .Cards {
    grid-template-columns: auto;
    margin-top: 13vw;
    grid-row-gap: 13vw;
  }
  .CardTitle {
    font-size: 7.73vw;
  }
  .CardContent {
    font-size: 3.73vw;
    line-height: 2;
  }
  .CardOverlay {
    height: 104.38vw;
    width: 78.6vw;
    border-radius: 4vw;
    border: 0.8vw solid #800e12;
  }
  .WhiteCard {
    border-radius: 4vw;
    height: 104.8vw;
    width: 78.4vw;
  }
  .TextsContainer {
    margin-top: 15vw;
  }
}
